<template>
	<div class="portal-header">
		<div class="portal-header__content">
			<div class="portal-header__nav" :class="{'portal-header__nav--open': navOpen}">
				<img src="~@/assets/images/correspondent-logo.png" class="portal-header__logo" alt="SouthState Correspondent" />
			</div>

			<div class="user-nav">
				<a :href="`${appUrl}/authenticate?redirect_uri=${redirectTo}`" class="button button--text button--reverse button--medium" v-if="!user">
					<ion-icon name="enter"></ion-icon>
					<span>Sign In</span>
				</a>

				<a :href="`${appUrl}`" class="button button--text button--reverse button--medium" v-if="user">
					<ion-icon name="apps"></ion-icon>
					<span>Resources</span>
				</a>
				<a @click="logout(redirectTo)" class="button button--text button--reverse button--medium" v-if="user">
					<ion-icon name="exit"></ion-icon>
					<span>Sign Out</span>
				</a>
			</div>
		</div>
	</div>
</template>

<script>
// import VueRouter from 'vue-router'
// const router = new VueRouter({
// 	base: process.env.APP_URL || process.env.VUE_APP_APP_URL,
// })

import MenuIcon from '@/components/MenuIcon'
// import FrameHeader from '@/components/FrameHeader'
import { auth } from '@/lib/auth'
import { router } from '@/lib/router'
// import { store } from '@/lib/store'

export default {
	// router,
	// store,
	name: 'PortalHeader',
	components: {
		MenuIcon,
	},
	data: () => ({
		appUrl: process.env.APP_URL || process.env.VUE_APP_APP_URL,
		menuOpen: false,
		navOpen: false,
		redirectTo: window.location.href,
	}),
	computed: {
		dashNav() {
			return [
				{
				title: 'My Profile',
				icon: 'person',
				link: {
					path: '/profile',
				},
			},
			{
				title: 'Help & Support',
				icon: 'help-buoy',
				link: {
					path: '/help',
				},
			},
			]
			// return this.$store.getters['navigation/menu']('dashboard')
		},
		userNav() {
			return [
				{
					title: 'Resources',
					icon: 'apps',
					link: {
						path: '/',
					},
				}
			]
			// return this.$store.getters['navigation/menu']('user')
		},
		user() {
			const user = auth.getUser()
			return user
		},
	},
	mounted() {

	},
	methods: {
		logout(redirectTo) {
			auth.logout()
			let sep = redirectTo.indexOf('?') > 0 ? '&' : '?'
			window.location.href = redirectTo ? [redirectTo, 'logout=true'].join(sep) : [this.appUrl,'authenticate'].join('/')
		},
		toggleMenu() {
			this.menuOpen = !this.menuOpen
		},
		routePath(item) {
			let path
			try {
				path = router.resolve(item.link)
			} catch (error) {
				console.warn(error)
			}
			if (path) {
				return this.appUrl + path.href
			}
		},
		toggleNav() {
			this.navOpen = !this.navOpen
		},
	},
	filters: {
		initials(val, count = 2) {
			if (!val) return val
			let words = val.split(' ')
			if (!words.length) return
			let initials = ''
			for (const word of words) {
				initials += word.charAt(0)
			}

			return initials
		}
	},
}
</script>

<style lang="scss">
@import 'styles/modules';
@import 'styles/partials';
$hamburger-padding-x: 10px;
$hamburger-padding-y: 10px;
$hamburger-layer-width: 20px;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 3px;
$hamburger-layer-color: modules.color_('text');
$box-shadow: 0 6px 6px -3px rgb(0 0 0 / 20%), 0 10px 14px 1px rgb(0 0 0 / 14%), 0 4px 18px 3px rgb(0 0 0 / 12%);

@import 'hamburgers/_sass/hamburgers/hamburgers';
.portal-header {
	background-color: modules.color_('primary');
	display: flex;
	height: 60px;
	font-family: modules.$font-family-primary;

	a, .a {
		cursor: pointer;
	}

	&__logo {
		width: 36px;
	}

	&__content {
		align-items: center;
		display: flex;
		flex: 1;
		justify-content: space-between;
		padding: 0 1rem;
	}

	&__nav {
		$nav: &;
		align-items: center;
		display: flex;
		position: relative;

		&-button {
			color: modules.color_('text', 'light');
			cursor: pointer;
			position: relative;
			@include modules.fontSize(24px);
			transition: color 150ms modules.$ease-out;
			z-index: 10;

			#{$nav}--open & {
				color: modules.color_('primary', 'text');
			}
		}

		&-items {
			@include modules.gutter('margin-top', 0.5);
			@include modules.gutter('padding');
			// @include elevation.elevation(10);
			background-color: modules.color_('background', 'dark');
			box-shadow: $box-shadow;
			display: flex;
			flex-direction: column;
			opacity: 0;
			position: absolute;
			pointer-events: none;
			left: 0;
			transform: translateY(-20%);
			transition: all 150ms modules.$ease-out;
			z-index: 9;
			top: 100%;

			#{$nav}--open & {
				opacity: 1;
				pointer-events: all;
				transform: translateY(0);
			}
		}

		&-item {
			align-items: center;
			color: modules.color_('text', 'medium');
			display: flex;
			flex-direction: row;
			font-size: 16px;
			font-weight: 600;
			line-height: 1.375;
			margin-bottom: 0.5em;
			margin-top: 0.5em;
			text-decoration: none;

			span {
				border-bottom: 1px solid transparent;
				transition: all 150ms linear;

				&:hover {
					color: modules.color_('text', 'accent');
				}
			}

			&--active {
				color: modules.color_('primary', 'text');

				span {
					&:hover {
						border-bottom-color: modules.color_('text', 'accent');
						color: modules.color_('primary', 'text');
					}
				}

			}

			&-title {
				margin-left: 1em;
				white-space: pre;
			}
		}
	}
}


.user-nav {
	$nav: &;
	align-items: center;
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	position: relative;

	.button span {
		margin-left: 10px;
	}

	&__avatar {
		align-items: center;
		background-color: modules.color_('primary');
		border-radius: 50%;
		cursor: pointer;
		display: flex;
		height: 30px;
		justify-content: center;
		margin-right: 10px;
		position: relative;
		width: 30px;

		&:after {
			color: white;
			content: attr(data-initials);
			font-size: 10px;
			font-weight: 600;
			letter-spacing: 2px;
		}
	}

	&__nav {
		// $nav: &;
		align-items: center;
		display: flex;
		position: relative;

		&-arrow {
			color: modules.color_('text', 'light');
			cursor: pointer;
			transition: transform 150ms modules.$ease-out;

			#{$nav}--open & {
				transform: rotate(180deg);
			}
		}

		&-items {
			// @include elevation.elevation(10);
			@include modules.gutter('right', 0.5);
			background-color: white;
			bottom: 0;
			box-shadow: $box-shadow;
			opacity: 0;
			pointer-events: none;
			position: absolute;
			// right: 0;
			transform: translateY(100%);
			transition: all 150ms modules.$ease-out;
			z-index: 5;

			#{$nav}--open & {
				opacity: 1;
				pointer-events: all;
				transform: translateY(120%);
			}
		}

		&-item {
			align-items: center;
			border-top: 1px solid modules.color_('border');
			color: modules.color_('text');
			display: flex;
			font-size: 14px;
			font-weight: 500;
			padding: 0.75rem 1rem;
			text-decoration: none;
			transition: all 150ms modules.$ease-standard;

			&:first-of-type {
				border-top: 0;
			}

			&:hover {
				background-color: modules.color_('secondary');
				color: modules.color_('secondary', 'text');
			}

			span {
				margin-left: 10px;
			}
		}
	}

	&__notifications {
		&-icon {
			color: modules.color_('text', 'light');
			@include modules.fontSize(24px);
			margin-right: 1.5em;
		}
	}
}
</style>
