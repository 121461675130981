<template>
	<button class="hamburger hamburger--squeeze" :class="{'is-active': active}" type="button" :aria-label="label" :aria-controls="controls">
		<span class="hamburger-box">
			<span class="hamburger-inner"></span>
		</span>
	</button>
</template>

<script>
export default {
	name: 'MenuIcon',
	props: {
		label: {
			type: String,
			default: 'Menu',
		},
		controls: {
			type: String,
			default: 'navigation',
		},
		active: {
			type: Boolean,
			default: false,
		},
	},
}
</script>

<style scoped lang="scss">
$hamburger-padding-x: 10px;
$hamburger-padding-y: 10px;
$hamburger-layer-width: 20px;
$hamburger-layer-height: 2px;
$hamburger-layer-spacing: 3px;
$hamburger-layer-color: modules.color_('text');

@import 'hamburgers/_sass/hamburgers/hamburgers';
</style>
