import { render, staticRenderFns } from "./MenuIcon.vue?vue&type=template&id=70ee56ca&scoped=true"
import script from "./MenuIcon.vue?vue&type=script&lang=js"
export * from "./MenuIcon.vue?vue&type=script&lang=js"
function injectStyles (context) {
  
  var style0 = require("./MenuIcon.vue?vue&type=style&index=0&id=70ee56ca&prod&scoped=true&lang=scss")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "70ee56ca",
  null
  ,true
)

export default component.exports